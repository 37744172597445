import { Component } from "react";

class BurgerTimer extends Component {
  public props: { view: string, timeChanged: Function, className?: string, restartCounter: Function } = {
    view: '',
    timeChanged: (value: number) => {},
    className: '',
    restartCounter: () => { console.debug('Property `restartCounter` has not been configured on BurgerTimer<' + this.props.view + '>'); }
  };
  public state: any = {
    showMenu: false
  }; 
  public requestTimerValues: {value: number, selected?: boolean}[] = [
    { value: 5 }, { value: 10 }, { value: 15 }, { value: 20 }, { value: 25 }, { value: 30 }, { value: 35 }, { value: 40 }, { value: 45 }, { value: 50 }
  ];
  private timerInterval: any;

  constructor(props: any) {
    super(props);
    this.props = props;
  }

  public componentDidMount(): void {
    this.getSession();
  }

  public componentWillUnmount(): void {
    this.timerInterval && window.clearInterval(this.timerInterval);
    this.timerInterval = null;
  }

  public render() {
    return (
      <section className="flex flex--direction-row">

        <section
          onClick={() => this.selectTime({ value: this.state.selectedTime })}
          className="width--20 pad--left-10 pad--right-10 background--blue-70 border-left--blue-45"
        >
          <section className="icon icon--refresh"></section>
        </section>

        <section onClick={() => this.toggleTimerMenu()} className={`is-relative width--40 pad--left-10 pad--top-5 pad--bottom-5 pad--right-10 background--blue-70 border-left--blue-45 ${this.props.className || ''}`}>
          <section className="flex flex--align-center text--color-blue font--size-8">
            {this.state.selectedValue}s
            <section className="icon icon--chevron-down icon--color-gray width--10__force height--10__force mar--left-10"></section>
          </section>
          {
            this.state.showMenu ?
            <ul className="is-absolute pos--top-120 pos--right-0 flex flex--direction-cols width--60 text--color-white shadow--outset-dark list--style-none z-index--40 mar--0 pad--0 font--size-8 pad--10 up-arrow">
              {
                this.requestTimerValues.map((item: any, index: number) => 
                  <li
                    className={`pad--top-5 pad--bottom-5 pad--left-20 pad--right-20 border-bottom--blue-dark ${item.selected ? 'background--blue-45' : 'background--blue-70'}`}
                    key={`timer-${index}`}
                    onClick={() => this.selectTime(item)}
                  >{item.value}</li>
                )
                
              }
            </ul> :
            <></>
          }
        </section>
      </section>
    )
  }

  private toggleTimerMenu(): any {
    this.setState({ showMenu: !this.state.showMenu });
  }

  private selectTime(time: { value: number, selected?: boolean }): any {
    this.requestTimerValues.forEach(item => item.selected = item.value === time.value);
    this.setSession(time.value);
    this.props.timeChanged(time.value);
    this.setState({ selectedTime: time.value, selectedValue: time.value });

    this.startCounter();
  }

  private startCounter(): void {
    this.timerInterval && window.clearInterval(this.timerInterval);
    this.timerInterval = null;

    this.timerInterval = setInterval(() => {
      let _time = this.state.selectedValue - 1;
      this.setState({ selectedValue: _time });
      if (_time === 0) {
        window.clearInterval(this.timerInterval);
        this.timerInterval = null;
        this.props.restartCounter(() => {
          this.setState({ selectedValue: this.state.selectedTime });
          this.startCounter();
        });
      }
    }, 1000);
  }

  private getSession(): void {
    try {
      const session: any = localStorage.getItem('zai-dashboard');
      const parsedSession = JSON.parse(session);
      this.selectTime({ value: parsedSession[this.props.view].timer });
    } catch(e) {
      this.selectTime(this.requestTimerValues[5]);
    }
  }

  private setSession(time: number): void {
    let parsedSession;
    try {
      const session: any = localStorage.getItem('zai-dashboard');
      parsedSession = JSON.parse(session);
    } catch(e) {}
    const session = {
      ...parsedSession,
      [this.props.view]: { timer: time }
    }
    localStorage.setItem('zai-dashboard', JSON.stringify(session));
  }
}

export default BurgerTimer;