import { userConstants } from '../_constants';

const data = sessionStorage.getItem('zai-user');
const user: IUser | null = data ? JSON.parse(data) : null;

const initialState: { loggedIn: string, user: IUser } | {} = user ? { loggedIn: !!user, user } : {};

export function authentication(state = initialState, action: { type: string, data: IUser }): ILogginIn | ILoggedIn | IError | {} | null {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.data
      };
    case userConstants.LOGIN_SUCCESS:
      let data: IUser = action.data;
      
      return {
        loggedIn: true,
        user: {
          ...action.data,
          ...data
        }
      };
    case userConstants.LOGIN_FAILURE:
      return {
        error: true
      };
    case userConstants.LOGOUT:
      sessionStorage.removeItem('zai-user');
      return null;
    default:
      return state
  }
}