import { combineReducers } from "redux"
import { alert } from "./alert.reducer"
import { app } from "./app.reducer";
import { authentication } from "./authentication.reducer"
import { organisation } from "./organisatoin.reducer";
import dashboard from "./dashboard.reducer";


export default combineReducers({
  app: app,
  alert: alert,
  authentication: authentication,
  organisation: organisation,
  dashboard: dashboard
});