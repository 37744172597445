export const dashboardConstants = {
  SERVICE_LIVE_DASHBOARD_REQUEST: 'SERVICE_LIVE_DASHBOARD:REQUEST',
  SERVICE_LIVE_DASHBOARD_SUCCESS: 'SERVICE_LIVE_DASHBOARD:SUCCESS',
  SERVICE_LIVE_DASHBOARD_FAILURE: 'SERVICE_LIVE_DASHBOARD:FAILURE',
  SERVICE_LIVE_DASHBOARD_CLEAR: 'SERVICE_LIVE_DASHBOARD:CLEAR',
  
  WORK_TYPES_DASHBOARD_REQUEST: 'WORK_TYPES_DASHBOARD:REQUEST',
  WORK_TYPES_DASHBOARD_SUCCESS: 'WORK_TYPES_DASHBOARD:SUCCESS',
  WORK_TYPES_DASHBOARD_FAILURE: 'WORK_TYPES_DASHBOARD:FAILURE',
  WORK_TYPES_DASHBOARD_CLEAR: 'WORK_TYPES_DASHBOARD:CLEAR',
  
  CONTACT_CENTRE_TEAMS_DASHBOARD_REQUEST: 'CONTACT_CENTRE_TEAMS_DASHBOARD:REQUEST',
  CONTACT_CENTRE_TEAMS_DASHBOARD_SUCCESS: 'CONTACT_CENTRE_TEAMS_DASHBOARD:SUCCESS',
  CONTACT_CENTRE_TEAMS_DASHBOARD_FAILURE: 'CONTACT_CENTRE_TEAMS_DASHBOARD:FAILURE',
  CONTACT_CENTRE_TEAMS_DASHBOARD_CLEAR: 'CONTACT_CENTRE_TEAMS_DASHBOARD:CLEAR',
  
  CONTACT_CENTRE_AGENTS_DASHBOARD_REQUEST: 'CONTACT_CENTRE_AGENTS_DASHBOARD:REQUEST',
  CONTACT_CENTRE_AGENTS_DASHBOARD_SUCCESS: 'CONTACT_CENTRE_AGENTS_DASHBOARD:SUCCESS',
  CONTACT_CENTRE_AGENTS_DASHBOARD_FAILURE: 'CONTACT_CENTRE_AGENTS_DASHBOARD:FAILURE',
  CONTACT_CENTRE_AGENTS_DASHBOARD_CLEAR: 'CONTACT_CENTRE_AGENTS_DASHBOARD:CLEAR',
  
};