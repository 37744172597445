export const APP = {
  SHOW_LOADER: 'LOADER:SHOW',
  HIDE_LOADER: 'SLOADER:HIDE',
};

export const CHANNELS: IChannelCode[] = [
  { name: 'Call', code: 'INBOUND_CALL' },
  { name: 'Email', code: 'EMAIL' },
  { name: 'SMS', code: 'SMS' },
  { name: 'Instant Message', code: 'INSTANT_MESSAGE' },
  { name: 'Chat', code: 'CHAT' },
  { name: 'Ticket', code: 'TICKET' },
];

export const COLORS = {
  RED: 'red',
  LIGHT_RED: 'red-light',
  ORANGE: 'orange',
  GREEN: 'green',
  LIGHT_GREEN: 'green-light',
  GREY: 'grey'
};

export const LOADER = { // this is unique per view/request
  ORGANISATION_INFO: 'organisationInfoDashboard',
  INBOUND_SERVICES: 'InboundServices',
  INBOUND_WORKTYPES: 'InboundWorkTypes',
  CONTACT_CENTRE_MONITORING: 'contactCentreMonitoring',
  CONTACT_CENTRE_MONITORING_TEAM: 'contactCentreMonitoringTeam'
}