export class ArrayTools {

  public static containsAll(a1: any, a2: any): boolean {
    if (a1.length !== a2.length) {
      return false;
    }
    for (let i1 of a1) {
      if (a2.indexOf(i1) === -1) {
        return false;
      }
    }
    return true;
  }

  public static sort(list: any[], key: string, keyToIgnore?: string): any {
    if(!list) return [];
    return list.sort(function(a,b) {
      let _a = a[key];
      let _b = b[key];
      if(!_a || !_b) return 0;
      if(keyToIgnore && (a[keyToIgnore] || b[keyToIgnore])) return 0;
      return (_a.toLowerCase() > _b.toLowerCase()) ? 1 : ((_b.toLowerCase() > _a.toLowerCase()) ? -1 : 0);
    });
  }
}