import { DashboardService } from '../_services/dashboard.service';
import { dashboardConstants } from './../_constants/dashboard.constants';

class DashboardActions {
  private dashboardService: DashboardService = new DashboardService();
  
  public retrieveServiceLiveDashboard(channel: string, pageNumber: number, pageSize: number): any {
    return (dispatch: any) => {
      dispatch(this.serviceLiveDashboardRequest({ pageNumber, pageSize }));
      this.dashboardService
        .retrieveServiceLiveDashboard(channel, pageNumber, pageSize)
        .then((data: IUser) => {
          dispatch(this.serviceLiveDashboardSuccess({ ...data, pageNumber, pageSize }));
        })
        .catch((error: Error) => {
          console.warn('retrieve service live dashboard failed', error);
          dispatch(this.serviceLiveDashboardFailure({ error }));
        })
    };
  }
  
  public retrieveTaskTemplateLiveDashboard(serviceId: string, channel: string, pageNumber: number, pageSize: number): any {
    return (dispatch: any) => {
      dispatch(this.workTypeLiveDashboardRequest({ pageNumber, pageSize }));
      this.dashboardService
        .retrieveTaskTemplateLiveDashboard(serviceId, channel, pageNumber, pageSize)
        .then((data: any) => {
          if (data) {
            dispatch(this.workTypeLiveDashboardSuccess({ ...data, pageNumber, pageSize }));
          } else {
            dispatch(this.workTypeLiveDashboardSuccess({ taskTemplates: [], pageNumber, pageSize }));
          }
        })
        .catch((error: Error) => {
          console.warn('retrieve worktype live dashboard failed', error);
          dispatch(this.workTypeLiveDashboardFailure({ error }));
        })
    };
  }
  
  public retrieveContactCenterMonitoringDashboard(pageNumber: number, pageSize: number): any {
    return (dispatch: any) => {
      dispatch(this.contactCentreMonitoringDashboardRequest({ pageNumber, pageSize }));
      this.dashboardService
        .retrieveTeamsLiveDashboard(pageNumber, pageSize)
        .then((data: any) => {
          dispatch(this.contactCentreMonitoringDashboardSuccess({ ...data, pageNumber, pageSize }));
        })
        .catch((error: Error) => {
          console.warn('retrieve contact centre dashboard failed', error);
          dispatch(this.contactCentreMonitoringDashboardFailure({ error }));
        })
    };
  }
  
  public retrieveContactCenterMonitoringAgentsDashboard(teamId: string, pageNumber: number, pageSize: number, searchText?: string): any {
    return (dispatch: any) => {
      dispatch(this.contactCentreMonitoringAgentsDashboardRequest({ pageNumber, pageSize }));
      this.dashboardService
        .retrieveAgentsLiveDashboard(teamId, pageNumber, pageSize, searchText)
        .then((data: any) => {
          dispatch(this.contactCentreMonitoringAgentsDashboardSuccess({ ...data, pageNumber, pageSize }));
        })
        .catch((error: Error) => {
          console.warn('retrieve contact centre agents dashboard failed', error);
          dispatch(this.contactCentreMonitoringAgentsDashboardFailure({ error }));
        })
    };
  }

  // CLEAR DASHBOARD
  public clearServiceLiveDashboard(): any {
    return (dispatch: any) => {
      dispatch(this.serviceLiveDashboardClear());
    }
  }

  public clearWorkTypeLiveDashboard(): any {
    return (dispatch: any) => {
      dispatch(this.workTypeLiveDashboardClear());
    }
  }

  public clearContactCentreMonitoringLiveDashboard(): any {
    return (dispatch: any) => {
      dispatch(this.contactCentreMonitoringDashboardClear());
    }
  }

  public clearContactCentreMonitoringAgentsLiveDashboard(): any {
    return (dispatch: any) => {
      dispatch(this.contactCentreMonitoringAgentsDashboardClear());
    }
  }

  // SERVICE LIVE DASHBOARD
  private serviceLiveDashboardRequest(data: any) { return { type: dashboardConstants.SERVICE_LIVE_DASHBOARD_REQUEST, data } }
  private serviceLiveDashboardSuccess(data: any) { return { type: dashboardConstants.SERVICE_LIVE_DASHBOARD_SUCCESS, data } }
  private serviceLiveDashboardFailure(error: any) { return { type: dashboardConstants.SERVICE_LIVE_DASHBOARD_FAILURE, error } }
  private serviceLiveDashboardClear() { return { type: dashboardConstants.SERVICE_LIVE_DASHBOARD_CLEAR } }

  // WORKTYPE LIVE DASHBOARD
  private workTypeLiveDashboardRequest(data: any) { return { type: dashboardConstants.WORK_TYPES_DASHBOARD_REQUEST, data } }
  private workTypeLiveDashboardSuccess(data: any) { return { type: dashboardConstants.WORK_TYPES_DASHBOARD_SUCCESS, data } }
  private workTypeLiveDashboardFailure(error: any) { return { type: dashboardConstants.WORK_TYPES_DASHBOARD_FAILURE, error } }
  private workTypeLiveDashboardClear() { return { type: dashboardConstants.WORK_TYPES_DASHBOARD_CLEAR } }

  // CONTACT CENTRE MONITORING DASHBOARD
  private contactCentreMonitoringDashboardRequest(data: any) { return { type: dashboardConstants.CONTACT_CENTRE_TEAMS_DASHBOARD_REQUEST, data } }
  private contactCentreMonitoringDashboardSuccess(data: any) { return { type: dashboardConstants.CONTACT_CENTRE_TEAMS_DASHBOARD_SUCCESS, data } }
  private contactCentreMonitoringDashboardFailure(error: any) { return { type: dashboardConstants.CONTACT_CENTRE_TEAMS_DASHBOARD_FAILURE, error } }
  private contactCentreMonitoringDashboardClear() { return { type: dashboardConstants.CONTACT_CENTRE_TEAMS_DASHBOARD_CLEAR } }

  // CONTACT CENTRE MONITORING AGENTS DASHBOARD
  private contactCentreMonitoringAgentsDashboardRequest(data: any) { return { type: dashboardConstants.CONTACT_CENTRE_AGENTS_DASHBOARD_REQUEST, data } }
  private contactCentreMonitoringAgentsDashboardSuccess(data: any) { return { type: dashboardConstants.CONTACT_CENTRE_AGENTS_DASHBOARD_SUCCESS, data } }
  private contactCentreMonitoringAgentsDashboardFailure(error: any) { return { type: dashboardConstants.CONTACT_CENTRE_AGENTS_DASHBOARD_FAILURE, error } }
  private contactCentreMonitoringAgentsDashboardClear() { return { type: dashboardConstants.CONTACT_CENTRE_AGENTS_DASHBOARD_CLEAR } }

}

export const dashboardActions = new DashboardActions();
