import React, { Component } from 'react';
import { Cell, Label, LabelList, Pie, PieChart } from 'recharts';

const PIE_COLORS = ['#800020', '#124429', '#B34e24', '#808080'];

enum SMALL {
  width = 80,
  height = 70
}

enum MEDIUM {
  width = 120,
  height = 95
}

class ZPieChart extends Component {
  public static SIZES: {
    SMALL: SMALL,
    MEDIUM: MEDIUM
  } = {
    // @ts-ignore
    SMALL: {
      height: SMALL.height,
      width: SMALL.width
    } as SMALL,
    // @ts-ignore
    MEDIUM: {
      height: MEDIUM.height,
      width: MEDIUM.width
    } as MEDIUM
  };

  public props: { item: any, data:any[], innerLabel: any, size: SMALL | MEDIUM };

  constructor(props: any) {
    super(props);
    this.props = props;
  }

  public componentDidMount(): any {
    this.forceUpdate();
  }

  public render() {
    return (
      <PieChart width={90} height={70}>
        <Pie
          isAnimationActive={false}
          data={this.props.data.filter(item => !!item)}
          cx={40}
          cy={30}
          innerRadius={20}
          outerRadius={33}
          paddingAngle={0}
          dataKey="value"
          labelLine={false}
        >
          {this.props.data.filter(item => !!item).map((entry, index) => (
            <Cell
              key={`item-${index}`}
              fill={entry && entry.name ? PIE_COLORS[index % PIE_COLORS.length] : 'rgb(128, 128, 128)'}
              stroke='rgba(0, 0, 0, 0)'
            />
          ))}
          <LabelList className="font--size-5" dataKey="percentage" position="inside"  />
          
          <Label
            className="font--size-6"
            value={this.props.innerLabel}
            position="center"
            fill="grey"
            style={{
              fontFamily: "sans-serif,Roboto"
            }}
          />
        </Pie>
      </PieChart>
    )
  }
}

export default ZPieChart;