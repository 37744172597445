import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";

import { AuthService } from '../_services/auth.service';

import { appActions, userActions } from '../_actions';
import { ActionDispatcher, ApplicationState, store } from '../_helpers';
import { DashboardService } from '../_services/dashboard.service';

//@ts-ignore
class LoginView extends React.Component {

  state: any;
  public props: any;
  private storeUnsubscribe: any;
  private authService: AuthService = new AuthService();
  private dashboardService: DashboardService = new DashboardService();
  private applicationState: ApplicationState = new ApplicationState(store);

  constructor(props: any) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: '',
      showSSO: false,
      samlPath: ''
    };
    this.dashboardService.clearheaders();
  }

  public componentDidMount(): void {
    this.props.actionDispatcher.dispatch(
      userActions.logout()
    );
    this.getUrlSegments();
    this.subscribeToStoreChanges();
  }

  public componentWillUnmount(): void {
    this.storeUnsubscribe();
  }

  public render(): ReactElement<any, any> {
    return (
      // use sections instead of div and span - semantic
      <section className="Login height--full pos--fixed pos--fixed-full">
        <section className="flex flex--justify-around height--full overflow">
          <section className="panel pad--60 background--blue--shade-7 flex--position-center-v flex--position-center-v-flex-start--height-500 rounded-5 width--full width--full-max-width--500">
            <section className="logo logo--size-medium"></section>

            { this.state.error ? (
              <section
                className="error color-validation pad--bottom-20 text-center"
              >
                Something is not correct with what you have entered.
              </section>
            ) : (
              <section className="pad--bottom-23 pad--top-30"></section>
            ) }

            <form onSubmit={this.props.handleSubmit(() => this.attemptLogin())}>

              <section className="is-relative pad--top-10 pad--bottom-20">
                <input
                  name="email"
                  autoComplete="email"
                  {...this.props.register("email", {
                    required: true,
                    //eslint-disable-next-line
                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))+$/
                  })}
                  value={this.state.email}
                  autoFocus
                  onChange={(event: any) => this.handleEmailChange(event)}
                  spellCheck="false"
                  className="icon-input"
                  type="text"
                  placeholder="Email"
                />
                <section className="o-ztext-input__icon">
                  <section className="icon icon--envelope-closed"></section>
                </section>

                { this.props.errors.email?.type === 'required' ? (
                  <section className="error error--field-bottom">
                    <section>Please enter your email.</section>
                  </section>
                ) : (
                  this.props.errors.email?.type === 'pattern' ? (
                    <section className="error error--field-bottom">
                      <section>Please enter a valid email.</section>
                    </section>
                  ) : (
                    <section></section>
                  )
                )}
              </section>

              <section className="is-relative pad--top-10 pad--bottom-20">
                <input
                  name="password"
                  {...this.props.register("password", { required: true })}
                  value={this.state.password}
                  onChange={(event: any) => this.handlePasswordChange(event)}
                  spellCheck="false"
                  className="icon-input"
                  type="password"
                  placeholder="Password"
                />
                <section className="o-ztext-input__icon">
                  <section className="icon icon--lock"></section>
                </section>
                { this.props.errors.password ?
                  <section className="error error--field-bottom">
                    <section>Please enter your password.</section>
                  </section> :
                  <section></section>
                }
              </section>

              <section className="is-relative pad--top-20 pad--bottom-10 text-center">
                <button
                  type="submit"
                  className={`zbutton zbutton-pink width--full ` + (this.state.loggingIn ? 'u-faded-content' : '')}
                  onClick={this.props.handleSubmit(() => this.attemptLogin())}
                >Sign In</button>
              </section>

            </form>

            <section className="is-relative pad--top-10 pad--bottom-10 flex flex--justify-between">
              <a href="/#" onClick={() => this.navigateToForgotPassword()}>Forgot Password</a>
              <a href="/#" onClick={() => this.navigateToRegister()}>Sign Up</a>
            </section>

            { this.state.showSSO ? (
              <section className="is-relative pad--top-10 pad--bottom-10 text-center">
                <button
                  type="submit"
                  className="zbutton width--full"
                  onClick={() => this.samlLogin()}
                >Single Sign On</button>
              </section>
            ) : ( 
              <section></section>
            ) }

          </section>
        </section>
      </section>
    );
  }

  private subscribeToStoreChanges(): void {
    this.storeUnsubscribe = this.applicationState.subscribe(() => {
      if (!this.props) return;
      this.updateState();
    });
  }

  private updateState(): void {
    let { authentication } = this.applicationState.getState();
    // @ts-ignore
    let url = (new URL(document.location))

    if (authentication) {
      this.setState({ error: authentication.error, loggingIn: authentication.loggingIn });

      if (authentication.loggedIn && (url.pathname === '/login' || url.pathname === '/')) {
        this.authenticationSuccessful(authentication.user);
        this.props.navigation('/dashboard');
        this.props.actionDispatcher.dispatch(
          appActions.hideLoader('login')
        );
      }
    }
  }

  private getUrlSegments(): void {

    // @ts-ignore
    let params = (new URL(document.location)).searchParams;
    let accessCode = params.get('accessCode');

    if (accessCode) {
      this.loginUsingAccessCode(accessCode);
      return;
    }

    let pathname = window.location.pathname;
    
    if(pathname.startsWith('/login/')) {
      pathname = pathname.replace('/login/','');
      super.setState({ samlPath: pathname });
     
      this.authService
        .getLoginPath(pathname)
        .then((response) => {
          super.setState({ showSSO: response });
        });
    }
  }

  private handleEmailChange(event: any): void {
    super.setState({ email: event.target.value, error: false });
    this.props.reset();
  }

  private handlePasswordChange(event: any): void {
    super.setState({ password: event.target.value, error: false });
    this.props.reset();
  }

  private attemptLogin(): void {
    const { email, password } = this.state;

    super.setState({ error: false });
    
    this.props.actionDispatcher.dispatch(
      appActions.showLoader('login')
    );
    this.props.actionDispatcher.dispatch(
      userActions.login(email, password)
    );
  }

  private loginUsingAccessCode(accessCode: string): void {
    this.props.actionDispatcher.dispatch(
      userActions.loginWithAccessCode(accessCode)
    );
  }

  public authenticationSuccessful(response: IUser): void {
    this.dashboardService.setBearerToken(response.token);

    this.authService
      .reportLoginSuccess(response.email, response.passportId);
  }

  private samlLogin(): void {
    const origin = window.location.origin;
    
    window.location.href = origin + '/saml2/authenticate/' + this.state.samlPath + '?RelayState=' + origin;
  }

  private navigateToForgotPassword(): void {
    window.open(process.env.REACT_APP_API_REDIRECT_ENDPOINT + 'password');
  }

  private navigateToRegister(): void {
    window.open(process.env.REACT_APP_API_REDIRECT_ENDPOINT + 'join');
  }
}

export default function Login(props: any) {
  const navigation = useNavigate();
  const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();

  return <LoginView
    {...props}
    navigation={navigation}
    register={register}
    handleSubmit={handleSubmit}
    watch={watch}
    actionDispatcher={new ActionDispatcher(store)}
    reset={reset}
    errors={errors}
  />;
}
