import axios from './axios';
import { EncryptTools } from '../tools/EncryptTools';

const ANONYMOUS_TOKEN =
  'eyJhbGciOiJIUzUxMiJ9.eyJuYmYiOjE1MjUyNTY3ODgsImlzcyI6InphaWxhYiIsIlVTRVIiOiJ7XCJ1c2VySWRcIjpcIlwiLFwidXNlcm5hbWVcIjpcIlwiLFwib3JnYW5pc2F0aW9uSWRcIjpcIlwiLFwicm9sZXNcIjpbe1wib3JnYW5pc2F0aW9uSWRcIjpcIlwiLFwiYWNjb3VudFR5cGVcIjpcIlBFUlNPTlwiLFwicm9sZVwiOlwiQU5PTllNT1VTXCIsXCJtZW1iZXJJZFwiOlwiXCJ9XX0iLCJpYXQiOjE1MjUyNTY3ODgsImp0aSI6IjA4MWM0OGQzLWM1NDQtNGNlMS1iNTUxLTQ1NDIwYjk4OTFhZSJ9.gkS7aw6lMtq2JNHZscBbcgvVGbMzrrAhiy1ZxbOH6FFOHi0b1Ue27aD76vsbcc1H4gQwtb2D-lIGSUkyTn3pVA';

export class AuthService {
  public async login(email: string, password: string): Promise<IUser> {
    try {
      const encryptedPassword = EncryptTools.encrypt(password);
      const params = { email: email, password: encryptedPassword };
      const config = {
        headers: {
          Authorization: `Bearer ${ANONYMOUS_TOKEN}`,
        },
      };

      const response = await axios.put(
        'v1/user/passports/authenticate',
        JSON.stringify(params),
        config
      );

      return response.data;
    } catch (err: any) {
      throw new Error(err);
    }
  }

  public async loginWithAccessCode(accessCode: string): Promise<IUser> {
    try {
      const params = { accessCode };
      const config = {
        headers: {
          Authorization: `Bearer ${ANONYMOUS_TOKEN}`,
        },
      };

      const response = await axios.put(
        'v1/user/passports/authenticate-with-access-code',
        JSON.stringify(params),
        config
      );

      return response.data;
    } catch (err: any) {
      throw new Error(err);
    }
  }

  public async getLoginPath(path: string): Promise<any> {
    try {
      let params = {
        path: path,
        enabled: true,
      };

      const response = await axios.get('v1/organisation/organisations/login', {
        params,
      });

      return response.data;
    } catch (err: any) {
      throw new Error(err);
    }
  }

  public async reportLoginSuccess(
    email: string,
    passportId: string
  ): Promise<any> {
    try {
      let params = { email };

      const response = await axios.put(
        `v1/user/passports/${passportId}/login`,
        JSON.stringify(params),
        { params }
      );

      return response.data;
    } catch (err: any) {
      throw new Error(err);
    }
  }
}
