import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { appActions, organisationActions, userActions } from "../_actions";
import { LOADER } from "../_constants";
import { ActionDispatcher, ApplicationState, store } from "../_helpers";

class DashboardView extends React.Component {
  public props: any;
  public state: { [key: string]: any } = { organisation: undefined };
  private applicationState: ApplicationState = new ApplicationState(store);
  private storeUnsubscribe: any;

  public componentDidMount(): void {
    this.subscribeToStoreChanges();
  }

  private subscribeToStoreChanges(): void {
    this.storeUnsubscribe = this.applicationState.subscribe(() => {
      if (!this.props) return;
      this.updateState();
    });
    this.updateState();
  }
  
  private updateState(): void {
    let { app, authentication, organisation } = this.applicationState.getState();
    if (authentication && authentication.loggedIn) {

      if (authentication.user.hasTeamLeaderRole) {
        super.setState({ isTeamLeader: true });
      }

      if (organisation && organisation.organisationId) {
        
        if (this.canSetData('organisation', organisation)) {
          super.setState({ organisation });

          if (app && app.actions && app.actions.includes(LOADER.ORGANISATION_INFO)) {
            this.props.actionDispatcher.dispatch(
              appActions.hideLoader(LOADER.ORGANISATION_INFO)
            );
          }
        }
      } else  {
        if (!app || !app.actions || !app.actions.includes(LOADER.ORGANISATION_INFO)) {
          this.retrieveOrganisation();
        }
      }
    }
  }

  public componentWillUnmount(): void {
    this.storeUnsubscribe && this.storeUnsubscribe();
  }
  
  public render(): ReactElement<any, any> {
    return (
      <section className="flex flex--direction-cols">
        <header className="flex flex--justify-between flex--align-center">
          <section className="flex--grow-1 text--color-white pad--10 overflow--hidden-x text--ellipse font--size-20">&nbsp;{this.state.organisation?.organisationName}</section>
          <section className="text--color-blue pad--10">
            <section onClick={() => this.logout()} className="width--20 height--20 pad--10">
              <section className="icon icon--power"></section>
            </section>
          </section>
        </header>

        {
          this.state.isTeamLeader ?
            <summary className={`flex flex--direction-cols flex--justify-around contentHeight--120 ${!this.state.organisation ? 'u-faded-content' : ''}`}>
              <section></section>
              <button
                className="pad--top-100 pad--bottom-100 pad--left-100 pad--right-100 background--blue-70 border--none text--color-white font--size-20"
                onClick={() => this.navToInboundLiveDashboard()}
              >Inbound Live Dashboard</button>
              <button
                className="pad--top-100 pad--bottom-100 pad--left-100 pad--right-100 background--blue-70 border--none text--color-white font--size-20"
                onClick={() => this.navToContactCentreMonitoringDashboard()}
              >Contact Centre Monitoring</button>
              <section></section>
            </summary>:
            <></>
        }
      </section>
    )
  }

  private canSetData(state: string, data: any): boolean {
    return !((this.state[state] && !this.state[state].loading) || !data || data.loading);
  }

  private async retrieveOrganisation(): Promise<void> {
    try {
      this.props.actionDispatcher.dispatch(
        appActions.showLoader(LOADER.ORGANISATION_INFO)
      );
      this.props.actionDispatcher.dispatch(
        organisationActions.retrieveOrganisation()
      );
    } catch(e) {
      console.error(' > failed to retrieve organisation', e);
    }
  }

  private navToInboundLiveDashboard(): any {
    this.props.navigation('/inbound-live-dashboard');
  }

  private navToContactCentreMonitoringDashboard(): any {
    this.props.navigation('/contact-centre-monitoring');
  }

  private logout(): any {
    this.props.actionDispatcher.dispatch(
      userActions.logout()
    );
    this.props.navigation('/login');
  }
}

export default function Dashboard(props: any) {
  const navigation = useNavigate();

  return <DashboardView
    {...props}
    navigation={navigation}
    actionDispatcher={new ActionDispatcher(store)}
  />
}
