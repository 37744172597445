import { organisationConstants } from './../_constants/organisation.constants';
import { userConstants } from '../_constants';

const data = sessionStorage.getItem('zai-organisation');
const initialState: IOrganisation | null = data ? JSON.parse(data) : {};

export function organisation(state = initialState, action: { type: string, data: IOrganisation }): ILoading | IOrganisation | null {
  switch (action.type) {
    case organisationConstants.ORGANISATION_INFO_REQUEST:
      return {
        loading: true
      };
    case organisationConstants.ORGANISATION_INFO_SUCCESS:
      return action.data;
    case organisationConstants.ORGANISATION_INFO_FAILURE:
      return null;
    case userConstants.LOGOUT:
      sessionStorage.removeItem('zai-organisation');
      return null;
    default:
      return state
  }
}
