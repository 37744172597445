import { APP } from '../_constants';

export const appActions = {
  showLoader,
  hideLoader
};

function showLoader(action: string): IReduxResponse {
    return { type: APP.SHOW_LOADER, message: { action } };
}

function hideLoader(action: string): IReduxResponse {
    return { type: APP.HIDE_LOADER, message: { action } };
}