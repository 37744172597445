import axios from 'axios';

axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';
axios.defaults.headers.delete['Content-Type'] = 'application/json';

axios.defaults.headers.common['Authorization'] = 'Bearer ';

export default axios.create({
  baseURL: process.env.REACT_APP_API_QUERY_ENDPOINT
});