import React from "react";

class ErrorBoundaryClass extends React.Component {
  public state: any = { error: null, errorInfo: null };

  public componentDidCatch(error: any, errorInfo: any): void {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <section className="text--color-white">
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </section>
      );
    }

    return this.props.children;
  }
}

export default function ErrorBoundary(props: any) {
  return <ErrorBoundaryClass {...props}/>;
}