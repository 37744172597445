import axios from './axios';

export class OrganisationService {
  
  public async retrieveOrganisation(): Promise<any> {
    try {
      const response =
        await axios
          .get('v1/organisation/organisations/me/information');
      return response.data.organisation;
    } catch(err: any) {
      throw new Error(err);
    }
  }

}