import { Store } from 'redux';

export class ApplicationState {
  constructor(private store: Store) {}

  // FIXME: type safety on State
  public getState(): any {
    // todo: work on this
    return this.store.getState();
  }

  public subscribe(listener: () => void): Function {
    const unsubscribeFn = this.store.subscribe(listener) as Function;
    return unsubscribeFn;
  }
}
