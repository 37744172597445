export const userConstants = {
  LOGIN_REQUEST: 'LOGIN:REQUEST',
  LOGIN_SUCCESS: 'LOGIN:SUCCESS',
  LOGIN_FAILURE: 'LOGIN:FAILURE',
  LOGIN_ERROR: 'LOGIN:ERROR',
  CLEAR: 'LOGIN:CLEAR',
  LOGOUT: 'LOGIN:LOGOUT'
};

export const ROLES = {
  ADMINISTRATOR: 'Administrator',
  AGENT: 'Agent',
  TEAM_LEADER: 'Team Leader',
  OFFICE_EMPLOYEE: 'Office Employee',
  QA_MANAGER: 'QA Manager',
  QA: 'QA',
  CAMPAIGN_MANAGER: 'Campaign Manager'
};