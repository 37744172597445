export class Chart {
  public static Colorize(): void {
    let elements = document.querySelectorAll('.recharts-layer.recharts-pie > text > tspan');
      elements.forEach((element: any) => {
        if (element && element.innerHTML.length > 1 && element.innerHTML.indexOf(':') > -1) {
          const content =
            element.innerHTML
              .replace(/<tspan>/gi, '')
              .replace(/<\/tspan>/g, '')
              .split(':');

          const xPos = parseInt(element.attributes.x.nodeValue);
          const yPos = parseInt(element.attributes.dy.nodeValue);

          let value1 = content[0];
          let value2 = content[1];

          element.setAttribute('class', 'fill--green font--size-6');
          element.textContent = value1;
          element.setAttribute('x', xPos - (value1.length <= 2 ? 7 : 10));

          let textNodeDivider = element.parentElement.cloneNode(true);
          textNodeDivider.textContent = ':';
          textNodeDivider.setAttribute('dy', yPos + 3);
          textNodeDivider.setAttribute('class', 'fill--white font--size-6');

          let textNode = element.parentElement.cloneNode(true);
          textNode.textContent = value2;
          textNode.setAttribute('class', 'fill--red font--size-6');
          textNode.setAttribute('x', xPos + (value2.length <= 2 ? 7 : 10));
          textNode.setAttribute('dy', yPos + 4);

          element.parentElement.parentElement.append(textNodeDivider);
          element.parentElement.parentElement.append(textNode);
        }
      });
  }
}