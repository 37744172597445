import React from 'react';

export class Legend extends React.Component {
  public props: { data: any[] } = {
    data: []
  };
  public state: { isExpanded: boolean } = {
    isExpanded: true
  }
  constructor(props: any) {
    super(props);
    this.props = props;
  }

  public render() {
    return (
      
      <section className="flex text--color-white">
        <section
          className="name-col flex flex--justify-center"
          onClick={() => this.toggleLegendVisibility()}
        >
          <section className={`icon icon--chevron-down icon--color-gray width--20__force height--20__force mar--left-10 mar--top-10 ${this.state.isExpanded ? 'rotate--180' : ''}`}></section>
        </section>
        {
          this.props.data.map(item => 
            <section key={item.name} className="flex--basis-1-1 width--min-90">
              <section className="flex flex--direction-cols flex--align-center mar--left-5">
                <section className="font--size-9 text-left mar--5">{item.name}</section>
                {
                  this.state.isExpanded ?
                    <ul className="list--style-none pad--0 mar--0 mar--bottom-10__force font--size-8 width--full">
                      {
                        item.categories.map((cat: any, parentIndex: number) =>
                          cat.name ? 
                          <li key={cat.name} className="flex flex--align-center">
                            <section className={`background--${cat.color} text--color-white mar--bottom-5 flex--basis-1-1 text-center pad--top-5 pad--bottom-5`}>
                              {cat.name}
                            </section>
                          </li>: (
                            cat.multi ? (
                              <li key={`subs-${parentIndex}`} className="flex flex--align-center flex--basis-1-1 font--size-7">
                                {
                                  cat.multi.map((m_item: any, index: number) => 
                                    <section key={`multi-${index}`} className="flex width--full text-center">
                                      <section className={`flex--basis-1-1 text--color-${m_item.color}`}>
                                          {m_item.name}
                                        </section>
                                        {index !== cat.multi.length - 1 ? <section>:</section>: <></>}
                                    </section>
                                  )
                                }
                              </li>
                            ) :
                            <></>
                          )
                        )
                      }
                    </ul>:
                    <></>
                }
              </section>
            </section>
          )
        }
      </section>
    );
  }

  private toggleLegendVisibility(): void {
    const isExpanded = !this.state.isExpanded;
    this.setState({ isExpanded });
    if (isExpanded) {
      this.toggleContentSize(238);
    } else {
      this.toggleContentSize(122);
    }
  }

  private toggleContentSize(sizeToDistract: number): void {
    let services: HTMLElement | null = document.querySelector('#js-summary-services');
    if (services) {
      services.style.height = `calc(100vh - ${sizeToDistract}px)`;
    }
    let worktypes: HTMLElement | null = document.querySelector('#js-summary-worktypes');
    if (worktypes) {
      worktypes.style.height = `calc(100vh - ${sizeToDistract}px)`;
    }
    let teams: HTMLElement | null = document.querySelector('#js-summary-teams');
    if (teams) {
      teams.style.height = `calc(100vh - ${sizeToDistract}px)`;
    }
    let members: HTMLElement | null = document.querySelector('#js-summary-members');
    if (members) {
      members.style.height = `calc(100vh - ${sizeToDistract}px)`;
    }
  }
}