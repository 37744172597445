import moment from "moment";
import { Component } from "react";

class FromTimer extends Component {
  public props: { fromTimeStamp: any } = {
    fromTimeStamp: 0
  };
  private interval: any;
  public state: any = {};
  constructor(props: any) {
    super(props);
    this.props = props;
  }

  public componentDidMount(): void {
    if (this.props.fromTimeStamp === '00:00:00') {
      return this.setState({ fromTime: this.props.fromTimeStamp });
    }
    if (!this.props.fromTimeStamp) {
      return console.warn('No from timestamp given');
    }
    this.startCounter();
  }
  
  public componentWillUnmount(): void {
    this.interval && window.clearInterval(this.interval);
  }

  private startCounter(): void {
    this.interval = setInterval(() => {
      const diff = moment.duration(moment.utc().diff(moment(this.props.fromTimeStamp).utc()));
      const hours = diff.hours() > 9 ? diff.hours() : '0' + diff.hours();
      const minutes = diff.minutes() > 9 ? diff.minutes() : '0' + diff.minutes();
      const seconds = diff.seconds() > 9 ? diff.seconds() : '0' + diff.seconds();
      const readable = hours + ':' + minutes + ':' + seconds;

      this.setState({ fromTime: readable })
    }, 160)
  }

  public render() {
    return (
      <section className="flex flex--direction-row">
        {this.state.fromTime}
      </section>
    )
  }
}

export default FromTimer;