import { dashboardConstants } from '../_constants/dashboard.constants';
import { userConstants } from '../_constants';
import { combineReducers } from 'redux';

function servicesLiveDashboard(state: any = {}, action: { type: string, data: { channel: string, services: any[], pageNumber: number, pageSize: number, showMore: number } }): ILoading | {} | null {
  switch (action.type) {
    case dashboardConstants.SERVICE_LIVE_DASHBOARD_REQUEST:
      if (action.data.pageNumber > 0) {
        state.loading = true;
        return state;
      }
      return {
        loading: true
      };
    case dashboardConstants.SERVICE_LIVE_DASHBOARD_SUCCESS:
      if (!action.data.services) {
        return {
          ...state,
          loading: false,
          showMore: false
        };
      }
      if (action.data.pageNumber === 0) {
        return {
          ...action.data,
          loading: false,
          showMore: action.data.services.length === action.data.pageSize
        };
      } else if (action.data.pageNumber > 0 && state.services) {
        state.services = state.services.concat(action.data.services);
        return {
          ...state,
          loading: false,
          showMore: action.data.services.length === action.data.pageSize
        };
      }
      return {
        ...action.data,
        loading: false,
        showMore: action.data.services.length === action.data.pageSize
      };
    case dashboardConstants.SERVICE_LIVE_DASHBOARD_FAILURE:
    case dashboardConstants.SERVICE_LIVE_DASHBOARD_CLEAR:
    case userConstants.LOGOUT:
      return null;
    default:
      return state
  }
}

function workTypesLiveDashboard(state: any = {}, action: { type: string, data: { serviceName: string, channel: string, taskTemplates: any[], pageNumber: number, pageSize: number, showMore: number } }): ILoading | {} | null {
  switch (action.type) {
    case dashboardConstants.WORK_TYPES_DASHBOARD_REQUEST:
      if (action.data.pageNumber > 0) {
        state.loading = true;
        return state;
      }
      return {
        serviceName: state ? state.serviceName : undefined,
        loading: true
      };
    case dashboardConstants.WORK_TYPES_DASHBOARD_SUCCESS:
      if (!action.data.taskTemplates) {
        return {
          ...state,
          loading: false,
          showMore: false
        };
      }
      if (action.data.pageNumber === 0) {
        return {
          ...action.data,
          serviceName: action.data.serviceName || state.serviceName,
          loading: false,
          showMore: action.data.taskTemplates.length === action.data.pageSize
        };
      } else if (action.data.pageNumber > 0 && state.taskTemplates) {
        state.taskTemplates = state.taskTemplates.concat(action.data.taskTemplates);
        return {
          ...state,
          loading: false,
          showMore: action.data.taskTemplates.length === action.data.pageSize
        };
      }
      return {
        ...action.data,
        loading: false,
        showMore: action.data.taskTemplates.length === action.data.pageSize
      };
    case dashboardConstants.WORK_TYPES_DASHBOARD_CLEAR:
      return {
        ...state,
        serviceName: null,
        taskTemplates: null
      };
    case dashboardConstants.WORK_TYPES_DASHBOARD_FAILURE:
    case userConstants.LOGOUT:
      return null;
    default:
      return state
  }
}

function contactCentreMonitoringTeams(state: any = {}, action: { type: string, data: { teams: any[], pageNumber: number, pageSize: number, showMore: number } }): ILoading | {} | null {
  switch (action.type) {
    case dashboardConstants.CONTACT_CENTRE_TEAMS_DASHBOARD_REQUEST:
      if (action.data.pageNumber > 0) {
        state.loading = true;
        return state;
      }
      return {
        loading: true
      };
    case dashboardConstants.CONTACT_CENTRE_TEAMS_DASHBOARD_SUCCESS:
      if (!action.data.teams) {
        return {
          ...state,
          loading: false,
          showMore: false
        };
      }
      if (action.data.pageNumber === 0) {
        return {
          ...action.data,
          loading: false,
          showMore: action.data.teams.length === action.data.pageSize
        };
      } else if (action.data.pageNumber > 0 && state.teams) {
        state.teams = state.teams.concat(action.data.teams);
        return {
          ...state,
          loading: false,
          showMore: action.data.teams.length === action.data.pageSize
        };
      }
      return {
        ...action.data,
        loading: false,
        showMore: action.data.teams.length === action.data.pageSize
      };
    case dashboardConstants.CONTACT_CENTRE_TEAMS_DASHBOARD_FAILURE:
    case dashboardConstants.CONTACT_CENTRE_TEAMS_DASHBOARD_CLEAR:
    case userConstants.LOGOUT:
      return null;
    default:
      return state
  }
}

function contactCentreMonitoringAgents(state: any = {}, action: { type: string, data: { teamName: string, members: any[], pageNumber: number, pageSize: number, showMore: number } }): ILoading | {} | null {
  switch (action.type) {
    case dashboardConstants.CONTACT_CENTRE_AGENTS_DASHBOARD_REQUEST:
      if (action.data.pageNumber > 0) {
        state.loading = true;
        return state;
      }
      return {
        teamName: state ? state.teamName : undefined,
        loading: true
      };
    case dashboardConstants.CONTACT_CENTRE_AGENTS_DASHBOARD_SUCCESS:
      if (!action.data.members) {
        return {
          ...state,
          loading: false,
          showMore: false
        };
      }
      if (action.data.pageNumber === 0) {
        return {
          ...action.data,
          teamName: action.data.teamName || state.teamName,
          loading: false,
          showMore: action.data.members.length === action.data.pageSize
        };
      } else if (action.data.pageNumber > 0 && state.members) {
        state.members = state.members.concat(action.data.members);
        return {
          ...state,
          loading: false,
          showMore: action.data.members.length === action.data.pageSize
        };
      }
      return {
        ...action.data,
        loading: false,
        showMore: action.data.members.length === action.data.pageSize
      };
    case dashboardConstants.CONTACT_CENTRE_AGENTS_DASHBOARD_CLEAR:
      return {
        ...state,
        teamName: null,
        members: null
      };
    case dashboardConstants.CONTACT_CENTRE_AGENTS_DASHBOARD_FAILURE:
    case userConstants.LOGOUT:
      return null;
    default:
      return state
  }
}


export default combineReducers({
  servicesLiveDashboard: servicesLiveDashboard,
  workTypesLiveDashboard: workTypesLiveDashboard,
  contactCentreMonitoringTeams: contactCentreMonitoringTeams,
  contactCentreMonitoringAgents: contactCentreMonitoringAgents
});




