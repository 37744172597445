import React, { ReactElement } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";

import './App.scss';

import { ApplicationState, store } from './_helpers';
import Login from "./views/Login";
import ErrorBoundary from './_components/ErrorBoundary';
import InboundLiveDashboardServices from './views/InboundLiveDashboardServices';
import InboundLiveDashboardTaskTemplates from './views/InboundLiveDashboardWorkTypes';
import Dashboard from './views/Dashboard';
import ContactCentreMonitoring from './views/ContactCentreMonitoring';
import ContactCentreMonitoringTeam from './views/ContactCentreMonitoringTeam';
import { DashboardService } from './_services/dashboard.service';

console.debug('process.env.REACT_APP_ENV >>>> ', process.env.REACT_APP_ENV);
console.debug('process.env.REACT_APP_API_REDIRECT_ENDPOINT >>>> ', process.env.REACT_APP_API_REDIRECT_ENDPOINT);
console.debug('process.env.REACT_APP_API_QUERY_ENDPOINT >>>> ', process.env.REACT_APP_API_QUERY_ENDPOINT);
console.debug('process.env.REACT_APP_API_COMMAND_ENDPOINT >>>> ', process.env.REACT_APP_API_COMMAND_ENDPOINT);


const ProtectedRoute = (data : { user: IUser | undefined }) => {
  if (!data.user) {
    console.debug(' ---------- No user | navigate to login ')
    return <Navigate to='/login' replace />;
  }
  return <Outlet />;
};


class App extends React.Component {
  private applicationState: ApplicationState = new ApplicationState(store);
  private dashboardService: DashboardService = new DashboardService();
  public state: { user: IUser | undefined, loading: boolean } = {
    user: undefined,
    loading: false
  };
  private subscriptionUnsubscribe: any;
  private mounted: boolean = false;
  
  constructor(props: any) {
    super(props);
    this.subscribeToStoreChanges();
    this.getSession();
  }

  private subscribeToStoreChanges(): void {
    this.subscriptionUnsubscribe && this.subscriptionUnsubscribe();

    this.subscriptionUnsubscribe = this.applicationState.subscribe(() => {
      if (!this.props) return;
      this.updateState();
    });
  }

  private getSession(): void {
    const data = sessionStorage.getItem('zai-user');
    const user: IUser = data ? JSON.parse(data) : null; // add typings here
    if (!user) {
      return;
    }
    /* eslint-disable react/no-direct-mutation-state */
    this.state.user = user;
    
    this.dashboardService.setBearerToken(user.token);
  }

  private updateState(): void {
    let { app, authentication } = this.applicationState.getState();
    if (this.mounted) {
      this.setState({ loading: app.loading });
      if (authentication && authentication.user) {
        this.setState({ user: authentication.user });
      }
    }
  }
  
  public componentDidMount(): void {
    this.mounted = true; 
    this.updateState();
  }
  
  public render(): ReactElement<any, any> {
    return (
      <main className="pos--fixed pos--fixed-full">
        { this.state.loading ?
          <section className="loader">
            <section className="loaderBar"></section>
          </section>:
          <></>
        }
        <ErrorBoundary>
          <Router>
            <Routes>
              <Route path="/" element={<Login/>} />
              <Route path="/login" element={<Login/>} />
              <Route path="/login/:pathParam" element={<Login/>} />
              <Route element={<ProtectedRoute user={this.state.user} />}>
                <Route path="/dashboard" element={<Dashboard/>} />
                <Route path="/inbound-live-dashboard" element={<InboundLiveDashboardServices/>} />
                <Route path="/inbound-live-dashboard/:id" element={<InboundLiveDashboardTaskTemplates/>} />
                <Route path="/contact-centre-monitoring" element={<ContactCentreMonitoring/>} />
                <Route path="/contact-centre-monitoring/:id" element={<ContactCentreMonitoringTeam/>} />
              </Route>
            </Routes>
          </Router>
        </ErrorBoundary>
        {
          this.state.user ? 
          <></> :
          <section className="background-planet background-planet--with-glow"></section>
        }
      </main>
    );
  }
}

export default App;
