import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
//@ts-ignore

import { ActionDispatcher, store } from '../_helpers';
import ZPieChart from '../_components/PieChart';
import { ArrayTools } from '../_helpers/tools';
import BurgerTimer from '../_components/BurgerTimer';
import { Legend } from '../_components/Legend';
import { COLORS, LOADER } from '../_constants';
import { Chart } from '../tools/Chart';
import { ListViewAbstract } from '../_components/_ListAbstract';
import { dashboardActions } from '../_actions/dashboard.actions';


class ContactCenterMonitoringView extends ListViewAbstract {
  private legend = [
    { name: 'Interactions', categories: [
      { name: 'Missed', color: COLORS.RED }, { name: 'Connected', color: COLORS.GREEN }
    ] },
    { name: 'CC Connected', categories: [
      { name: 'No Disposition', color: COLORS.RED }, { name: 'Disposition', color: COLORS.GREEN }
    ] },
    { name: 'Agents', categories: [
      { name: 'Not Resp.', color: COLORS.RED }, { name: 'Conversing', color: COLORS.GREEN }, { name: 'Waiting', color: COLORS.ORANGE }
    ] }
  ];
  public props: any;

  public state: { [key: string]: any } = {
    memberId: null,
    organisation: null,
    searchEnabled: false,
    searchText: '',
    selectedChannel: ''
  }
  protected pageNumber: number = 0;
  protected loaderName = LOADER.CONTACT_CENTRE_MONITORING;
  protected loaders: string[] = [];
  protected keyName: string = 'teamName';

  public render(): ReactElement<any, any> {

    return (
      <section className="">
        <header className="flex flex--justify-between flex--align-center">
          {/* navigate back */}
          <button
            className="flex flex--align-center flex--justify-center background--blue-70 border-right--blue-45 width--60 height--full text-center border--none text--color-white font--size-15"
            onClick={() => this.navToDashboard()}
          >&#60;</button>
          <section className="flex--grow-1 text--color-white pad--10 overflow--hidden-x text--ellipse">&nbsp;{this.state.organisation?.organisationName}</section>
          <section className="text--color-blue pad--10">
            <section onClick={() => this.logout()} className="width--20 height--20 pad--10">
              <section className="icon icon--power"></section>
            </section>
          </section>
        </header>
        {
          this.state.searchEnabled ?
          <section className="is-relative">
            <section className="flex flex--justify-between">
              <section onClick={() => this.disableSearch()} className="pad--left-10 pad--top-5 pad--bottom-5 pad--right-10 background--blue-70 border-right--blue-45">
                <section className="icon icon--search width--20__force"></section>
              </section>
              <input
                name="searchText"
                value={this.state.searchText}
                spellCheck="false"
                autoComplete="off"
                className="flex--grow-1 background--blue-dark text--color-white pad--left-20 pad--right-20 pad--top-7 pad--bottom-7 border--none border-bottom--blue-45__force font--size-9"
                type="text"
                placeholder="Search teams"
                onChange={(event) => this.searchTextChanged(event)}
              />
            </section>
          </section> :
          <section className="flex flex--justify-between">
            <section className="background--blue-70 flex--basis-1-1 height--30__force"></section>
            <section onClick={() => this.enableSearch()} className="width--40 width--min-20 pad--left-10 pad--top-5 pad--bottom-5 pad--right-10 background--blue-70 border-left--blue-45">
              <section className="icon icon--search"></section>
            </section>
            <BurgerTimer
              view="contactCentreMonitoringDashboard"
              timeChanged={(time: number) => this.timeChanged(time)}
              restartCounter={((cb: Function) => this.restartCounter(cb))}
            ></BurgerTimer>
          </section>
        }
        <Legend data={this.legend}></Legend>
        <summary
          id="js-summary-teams"
          className="flex flex--direction-cols text--color-white overflow--auto-y u-scroll-bar u-scroll-bar--blue contentHeight--200"
        >
          {
            this.state.filteredData && this.state.filteredData.length > 0 ?
              this.state.filteredData.map((item: any, index: number) => 
                <summary
                  key={`item-${index}`}
                  className={`flex flex--align-center ${item.visible === false ? 'is-hidden' : ''} ${this.state.filteredData.length - 1 === index ? 'mar--bottom-100' : '' }`}
                  onClick={() => this.selectTeam(item.teamId, item.teamName)}
                >
                  <section className="font--size-6 name-col pad--left-10">
                    <section className={`text--wrap-none text--color-blue font--size-7 ${item.teamName.length > 15 ? 'marquee' : ''}`}>
                      <section style={{
                        animation: `marquee ${(
                          item.teamName.length > 45 ? item.teamName.length / 6 : (
                            item.teamName.length > 40 ? item.teamName.length / 5.5 : (
                              item.teamName.length > 35 ? item.teamName.length / 5 : (
                                item.teamName.length > 30 ? item.teamName.length / 4.5 : (
                                  item.teamName.length > 25 ? item.teamName.length / 4 : (
                                    item.teamName.length > 20 ? item.teamName.length / 3.5 : (
                                      item.teamName.length > 15 ? item.teamName.length / 3 : 0
                                    )
                                  )
                                )
                              )
                            )
                          )
                        )}s linear infinite`
                      }}>{item.teamName}</section>
                    </section>
                    <section>
                      AHT:&nbsp;{this.convertMilliSecondsToTime(item.averageHandleTime)}
                    </section>
                    <section className="flex">
                      Transfers:&nbsp;
                      {
                        item.transfers > 0 ?
                        <section style={{ background: '#800020', padding: '0 10px' }}>{item.transfers}</section> :
                        <section>{item.transfers}</section>
                      }
                    </section>
                  </section>

                  <section className="flex--basis-1-1">
                    <section className="flex flex--direction-cols flex--align-center">
                      <ZPieChart
                        size={ZPieChart.SIZES.SMALL}
                        item={item}
                        data={[{
                          name: 'Not Handled',
                          value: item.served.notHandled,
                          percentage: (item.served.notHandled > 0 ? -Math.round(-((item.served.notHandled / item.served.total) * 100)): 0) + '%'
                        }, {
                          name: 'handled',
                          value: -Math.round(-item.served.handled * 10) / 10,
                          percentage: (item.served.handled > 0 ? -Math.round(-((item.served.handled / item.served.total) * 100)): 0) + '%'
                        }, !(item.served.handled > 0 || item.served.notHandled > 0) ? {
                          name: '',
                          value: 100,
                          percentage: ''
                        } : null]}
                        innerLabel={item.served.total}
                      ></ZPieChart>
                    </section>
                  </section>

                  <section className="flex--basis-1-1">
                    <section className="flex flex--direction-cols flex--align-center">
                      <ZPieChart
                        size={ZPieChart.SIZES.SMALL}
                        item={item}
                        data={[{
                          name: 'No Disposition Selected',
                          value: -Math.round(-item.dispositions.notSelected * 10) / 10,
                          percentage: (item.dispositions.notSelected > 0 ? -Math.round(-((item.dispositions.notSelected / item.dispositions.total) * 100)): 0) + '%'
                        }, {
                          name: 'Disposition Selected',
                          value: -Math.round(-item.dispositions.selected),
                          percentage: (item.dispositions.selected > 0 ? -Math.round(-((item.dispositions.selected / item.dispositions.total) * 100)): 0) + '%'
                        }, (item.dispositions.selected > 0 || item.dispositions.notSelected > 0) ? 
                        null :
                        {
                          name: '',
                          value: 100,
                          percentage: ''
                        }]}
                        innerLabel={item.dispositions.total}
                      ></ZPieChart>
                    </section>
                  </section>

                  <section className="flex--basis-1-1">
                    <section className="flex flex--direction-cols flex--align-center">
                      <ZPieChart
                        size={ZPieChart.SIZES.SMALL}
                        item={item}
                        data={[{
                          name: 'Not Resp.',
                          value: item.agents.notResponding,
                          percentage: item.agents.notResponding
                        }, {
                          name: 'Conversing',
                          value: item.agents.conversing,
                          percentage: item.agents.conversing
                        }, {
                          name: 'Waiting',
                          value: item.agents.waiting,
                          percentage: item.agents.waiting
                        }, !(item.agents.notResponding > 0) && !(item.agents.conversing > 0) && !(item.agents.waiting > 0) ? {
                          name: '',
                          value: 100,
                          percentage: ''
                        } : null]}
                        innerLabel={item.agents.notResponding + item.agents.conversing + item.agents.waiting}
                      ></ZPieChart>
                    </section>
                  </section>
                </summary>
            ) :
            <section
              className="font--size-8 text--color-blue text-center pad--10 mar--top-20">
              No data to display.
            </section>
          }
          {
            this.state.showMore ?
            <section
              onClick={() => this.showMore()}
              className="font--size-8 background--blue-70 text--color-blue text-center pad--10 mar--top-20">
              SHOW MORE
            </section> :
            <></>
          }
        </summary>
      </section>
    );
  }
  
  protected updateState(): void {
    let { app, authentication, organisation, dashboard } = this.applicationState.getState();

    if (authentication && authentication.loggedIn) {
      this.loaders = app.actions || [];
      if (organisation) {
        super.setState({ organisation, memberId: authentication.user.memberId });
      }
      if (!dashboard.contactCentreMonitoringTeams || dashboard.contactCentreMonitoringTeams.loading) return;
    
      if (dashboard.contactCentreMonitoringTeams && dashboard.contactCentreMonitoringTeams.teams) {
        this.dataRetrieved(dashboard.contactCentreMonitoringTeams);
      }
    }
  }

  protected retrieveData(): void {
    if (!this.state.memberId) {
      setTimeout(() => {
        this.retrieveData();
      }, 200)
      return;
    }
    try {
      if (this.pageNumber === 0) {
        this.clearState();
      }
      this.showLoader();
      
      this.props.actionDispatcher.dispatch(
        dashboardActions
          .retrieveContactCenterMonitoringDashboard(this.pageNumber, this.pageSize)
      );
    } catch(e) {
      console.error(' > failed to retrieve dashboard', e);
      this.hideLoader();
    }
  }

  private dataRetrieved(data: any): void {
    if (!this.state.dataList || this.state.dataList.length !== data.teams.length) {
      let teams = ArrayTools.sort(data.teams, 'name');

      super.setState({
        dataList: teams,
        filteredData: teams,
        showMore: data.showMore
      });
      this.restartCounterCallback && this.restartCounterCallback();
      Chart.Colorize();
      this.hideLoader();
    }
  }

  protected clearState(): void {
    this.props.actionDispatcher.dispatch(
      dashboardActions.clearContactCentreMonitoringLiveDashboard()
    );
    this.setState({ filteredData: null, dataList: null });
  }

  protected showMore(): any {
    this.pageNumber += 1;
    this.retrieveData();
  }

  private selectTeam(teamId: string, teamName: string): void {
    this.props.navigation(`/contact-centre-monitoring/${teamId}`, { state: { teamName } });
  }

  public navToDashboard(): any {
    this.props.navigation('/dashboard');
  }
}

export default function ContactCenterMonitoring(props: any) {
  const navigation = useNavigate();

  return <ContactCenterMonitoringView
    {...props}
    navigation={navigation}
    actionDispatcher={new ActionDispatcher(store)}
  />;
}
