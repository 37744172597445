import { APP } from '../_constants';

export function app(state: any = {}, action: any) {
  if (!action.message) return state;

  switch (action.type) {
    case APP.SHOW_LOADER:
      if (state.actions) {
        if (state.actions.indexOf(action.message.action) === -1) {
          state.actions.push(action.message.action);
        }
        return {
          loading: true,
          actions: state.actions
        };
      }

      return {
        loading: true,
        actions: [action.message.action]
      };
    case APP.HIDE_LOADER:
      const mappedActions = state.actions.filter((_action: any) => _action !== action.message.action);

      return {
        loading: mappedActions.length > 0,
        actions: mappedActions
      };
    default:
      return state;
  }
}