import axios from './axios';

// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
export class DashboardService {
  
  public async retrieveServiceLiveDashboard(channel: string, pageNumber: number, pageSize: number): Promise<any> {
    try {
      const response =
        await axios
          .get('/m1/interaction/live-dashboard/services', {
            params: { channel, page: pageNumber, pageSize }
          });
      if (!response || !response.data || response.status === 204) {
        return [];
      }
      return response.data;
    } catch(err: any) {
      throw new Error(err);
    }
  }

  public async retrieveTaskTemplateLiveDashboard(serviceId: string, channel: string, pageNumber: number, pageSize: number): Promise<any> {
    try {
      const response = 
        await axios
          .get(`/m1/interaction/live-dashboard/services/${serviceId}`, {
            params: { channel, page: pageNumber, pageSize }
          });

          if (!response || !response.data || response.status === 204) {
        return [];
      }
      return response.data;
    } catch(err: any) {
      throw new Error(err);
    }
  }
  
  public async retrieveTeamsLiveDashboard(pageNumber: number, pageSize: number): Promise<any> {
    try {
      const response =
        await axios
          .get('/m1/interaction/contact-centre-monitoring/teams', {
            params: { page: pageNumber, pageSize }
          });
      if (!response || !response.data || response.status === 204) {
        return { teams: [] };
      }
      return response.data;
    } catch(err: any) {
      throw new Error(err);
    }
  }

  public async retrieveAgentsLiveDashboard(teamId: string, page: number, pageSize: number, searchText?: string): Promise<any> {
    try {
      const response = 
        await axios
          .get(`/m1/interaction/contact-centre-monitoring/teams/${teamId}`, {
            params: { page, pageSize, name: searchText }
          });
      if (!response || !response.data || response.status === 204) {
        return { members: [] };
      }
      return response.data;
    } catch(err: any) {
      throw new Error(err);
    }
  }

  public clearheaders(): void {
    // @ts-ignore
    axios.interceptors.request.forEach((item: any, index: number) => {
      axios.interceptors.request.eject(index);
    })
    // @ts-ignore
    axios.interceptors.request.handlers = [];
  }

  public setBearerToken(token: string): void {
    axios.interceptors.request.use(function test (config: any) {
      config.headers.Authorization = 'Bearer ' + token;
      config.headers.Session = uuidv4();
      return config;
    });
  }
}