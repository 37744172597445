import { ReactElement } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
//@ts-ignore
import debounce from 'lodash.debounce';

import { ActionDispatcher, store } from '../_helpers';
import ZPieChart from '../_components/PieChart';
import { ArrayTools } from '../_helpers/tools';
import BurgerTimer from '../_components/BurgerTimer';
import { Legend } from '../_components/Legend';
import { CHANNELS, COLORS, LOADER } from '../_constants';
import { Chart } from '../tools/Chart';
import { ListViewAbstract } from '../_components/_ListAbstract';
import { dashboardActions } from '../_actions/dashboard.actions';
import FromTimer from '../_components/FromTimer';

class InboundLiveDashboardTaskTemplatesView extends ListViewAbstract {

  private legend = [
    { name: 'Waiting', categories: [
      { name: 'Out SLA', color: COLORS.RED }, { name: 'In SLA', color: COLORS.GREEN }
    ] },
    { name: 'Received', categories: [
      { name: 'Not Handled', color: COLORS.RED }, { name: 'Handled', color: COLORS.GREEN }
    ] },
    { name: 'Agents', categories: [
      { name: 'Not Resp.', color: COLORS.RED }, { name: 'Conversing', color: COLORS.GREEN }, { name: 'Waiting', color: COLORS.ORANGE }, { multi: [
        { name: 'On Duty', color: COLORS.LIGHT_GREEN }, { name: 'Off Duty', color: COLORS.LIGHT_RED }
      ] }
    ] }
  ];
  public props: any;
  private channels;
  public state: { [key: string]: any } = {
    organisation: null,
    searchEnabled: false,
    searchText: '',
    selectedChannel: ''
  }
  protected pageNumber: number = 0;
  protected loaderName = LOADER.INBOUND_WORKTYPES;
  protected loaders: string[] = [];
  protected keyName: string = 'name';

  constructor(props: any) {
    super(props);
    this.channels = [{ name: 'All', code: 'All', selected: true }].concat(JSON.parse(JSON.stringify(CHANNELS)));
    this.setState({ selectedChannel: this.channels[0].code });
  }

  public render(): ReactElement<any, any> {

    return (
      <section className="flex flex--direction-cols">
        <header className="flex flex--justify-between flex--align-center">
          {/* navigate back */}
          <button
            className="flex flex--align-center flex--justify-center background--blue-70 border-right--blue-45 width--60 height--full text-center border--none text--color-white font--size-15"
            onClick={() => this.navToServicesDashboard()}
          >&#60;</button>
          <section className="flex--grow-1 text--color-white pad--10 overflow--hidden-x text--ellipse">&nbsp;Service: {this.state.serviceName}</section>
          <section className="text--color-blue pad--10">
            <section onClick={() => this.logout()} className="width--20 height--20 pad--10">
              <section className="icon icon--power"></section>
            </section>
          </section>
        </header>
        {
          this.state.searchEnabled ?
          <section className="is-relative">
            <section className="flex flex--justify-between">
              <section onClick={() => this.disableSearch()} className="pad--left-10 pad--top-5 pad--bottom-5 pad--right-10 background--blue-70 border-right--blue-45">
                <section className="icon icon--search width--20__force"></section>
              </section>
              <input
                name="searchText"
                value={this.state.searchText}
                spellCheck="false"
                autoComplete="off"
                className="flex--grow-1 background--blue-dark text--color-white pad--left-20 pad--right-20 pad--top-7 pad--bottom-7 border--none border-bottom--blue-45__force font--size-9"
                type="text"
                placeholder="Search worktypes"
                onChange={(event) => this.searchTextChanged(event)}
              />
            </section>
          </section> :
          <section className="flex flex--justify-between">
            <ul className="flex flex--position-flex-start font--size-9 flex--wrap-none text--wrap-none list--style-none pad--0 mar--0 overflow--auto-x u-scroll-bar u-scroll-bar--thick u-scroll-bar--blue shadow--inset-right">
              {
                // channel slider
                this.channels.map((channel: any) => 
                  <li
                    key={channel.code}
                    onClick={debounce(() => this.selectChannel(channel), 200)}
                    className={`background--blue-dark text--color-white pad--left-20 pad--right-20 pad--top-5 pad--bottom-5 border-right--blue-45 text-center ${channel.selected ? 'background--blue-60__force' : ''}`}
                  >{channel.name}</li>
                )
              }
            </ul>
            <section className='background--blue-70 flex--basis-1-1'></section>
            <section onClick={() => this.enableSearch()} className="width--40 width--min-20 pad--left-10 pad--top-5 pad--bottom-5 pad--right-10 background--blue-70 border-left--blue-45">
              <section className="icon icon--search"></section>
            </section>
            <BurgerTimer
              view="taskTemplateDashboard"
              timeChanged={(time: number) => this.timeChanged(time)}
              restartCounter={((cb: Function) => this.restartCounter(cb))}
            ></BurgerTimer>
          </section>
        }
        <Legend data={this.legend}></Legend>
        <summary
          id="js-summary-worktypes"
          className="flex flex--direction-cols text--color-white overflow--auto-y u-scroll-bar u-scroll-bar--blue contentHeight--200"
        >
          {
            this.state.filteredData && this.state.filteredData.length > 0 ?
              this.state.filteredData.map((item: any, index: number) => 
                <summary
                  key={`item-${index}`}
                  className={`flex flex--align-center ${item.visible === false ? 'is-hidden' : ''} ${this.state.filteredData.length - 1 === index ? 'mar--bottom-100' : '' }`}
                >
                  <section className="font--size-6 name-col pad--left-10">
                    <section className={`text--wrap-none text--color-blue font--size-7 ${item.name.length > 15 ? 'marquee' : ''}`}>
                      
                      <section style={{
                        animation: `marquee ${(
                          item.name.length > 45 ? item.name.length / 6 : (
                            item.name.length > 40 ? item.name.length / 5.5 : (
                              item.name.length > 35 ? item.name.length / 5 : (
                                item.name.length > 30 ? item.name.length / 4.5 : (
                                  item.name.length > 25 ? item.name.length / 4 : (
                                    item.name.length > 20 ? item.name.length / 3.5 : (
                                      item.name.length > 15 ? item.name.length / 3 : 0
                                    )
                                  )
                                )
                              )
                            )
                          )
                        )}s linear infinite`
                      }}>{item.name}</section>
                    </section>

                    <section
                      className={`${item.answeredInSLA30Min >= 0 ? (item.answeredInSLA30Min > 85 ? 'text--color-green' : 'text--color-red') : ''}`}
                    >In SLA 30min: {item.answeredInSLA30Min}</section>
                    <section>Longest Waiting:&nbsp;
                      <FromTimer fromTimeStamp={this.convertTimeStampToDate(item.longestWaiting)}></FromTimer>
                    </section>
                  </section>

                  <section className="flex--basis-1-1">
                    <section className="flex flex--direction-cols flex--align-center">
                      <ZPieChart
                        size={ZPieChart.SIZES.SMALL}
                        item={item}
                        data={[{
                          name: 'Out SLA',
                          value: -Math.round(-item.waiting.outOfSLAPercentage * 10) / 10,
                          percentage: -Math.round(-item.waiting.outOfSLAPercentage) + '%'
                        }, {
                          name: 'In SLA',
                          value: -Math.round(-item.waiting.inSLAPercentage * 10) / 10,
                          percentage: -Math.round(-item.waiting.inSLAPercentage) + '%'
                        }, !(item.waiting.inSLAPercentage > 0 || item.waiting.outOfSLAPercentage > 0) ? {
                          name: '',
                          value: 100,
                          percentage: ''
                        } : null]}
                        innerLabel={item.waiting.outOfSLACount + item.waiting.inSLACount}
                      ></ZPieChart>
                    </section>
                  </section>

                  <section className="flex--basis-1-1">
                    <section className="flex flex--direction-cols flex--align-center">
                      <ZPieChart
                        size={ZPieChart.SIZES.SMALL}
                        item={item}
                        data={[{
                          name: 'Not Handled',
                          value: -Math.round(-item.received.notHandledPercentage * 10) / 10,
                          percentage: -Math.round(-item.received.notHandledPercentage) + '%'
                        }, {
                          name: 'handled',
                          value: -Math.round(-item.received.handledPercentage * 10) / 10,
                          percentage: -Math.round(-item.received.handledPercentage) + '%'
                        }, (item.received.notHandledPercentage > 0 || item.received.handledPercentage > 0) ? 
                        null : 
                        {
                          name: '',
                          value: 100,
                          percentage: ''
                        }]}
                        innerLabel={item.received.count}
                      ></ZPieChart>
                    </section>
                  </section>

                  <section className="flex--basis-1-1">
                    <section className="flex flex--direction-cols flex--align-center">
                      <ZPieChart
                        size={ZPieChart.SIZES.SMALL}
                        item={item}
                        data={[{
                          name: 'Not Resp.',
                          value: item.agents.notRespondingCount,
                          percentage: item.agents.notRespondingCount
                        }, {
                          name: 'Conversing',
                          value: item.agents.conversingCount,
                          percentage: item.agents.conversingCount
                        }, {
                          name: 'Waiting',
                          value: item.agents.waitingCount,
                          percentage: item.agents.waitingCount
                        }, !(item.agents.notRespondingCount > 0) && !(item.agents.conversingCount > 0) && !(item.agents.waitingCount > 0) ? {
                          name: '',
                          value: 100,
                          percentage: ''
                        } : null]}
                        innerLabel={item.agents.readyCount + ':' + item.agents.notReadyCount}
                      ></ZPieChart>
                    </section>
                  </section>
                </summary>
            ) :
            <section
              className="font--size-8 text--color-blue text-center pad--10 mar--top-20">
              No data to display.
            </section>
          }
          {
            this.state.showMore ?
            <section
              onClick={() => this.showMore()}
              className="font--size-8 background--blue-70 text--color-blue text-center pad--10 mar--top-20">
              SHOW MORE
            </section> :
            <></>
          }
        </summary>
      </section>
    );
  }

  protected updateState(): void {
    let { app, authentication, dashboard } = this.applicationState.getState();

    if (authentication && authentication.loggedIn) {
      this.loaders = app.actions || [];
      if (!dashboard.workTypesLiveDashboard || dashboard.workTypesLiveDashboard.loading) return;
    
      if (dashboard.workTypesLiveDashboard && dashboard.workTypesLiveDashboard.taskTemplates) {
        this.dataRetrieved(dashboard.workTypesLiveDashboard);
      }
    }
  }

  protected retrieveData(channelCode: string): void {
    try {
      if (this.pageNumber === 0) {
        this.clearState();
      }
      this.showLoader();
      
      const serviceId = this.props.params.id;
      this.props.actionDispatcher.dispatch(
        dashboardActions
            .retrieveTaskTemplateLiveDashboard(serviceId, channelCode || this.channels[0].code, this.pageNumber, this.pageSize)
      );
    } catch(e) {
      console.error(' > failed to retrieve dashboard', e);
      this.hideLoader();
    }
  }

  private dataRetrieved(data: any): void {
    let workTypes = ArrayTools.sort(data.taskTemplates, 'name');

    super.setState({
      serviceName: data.serviceName,
      dataList: workTypes,
      filteredData: workTypes,
      showMore: data.showMore
    });
    this.restartCounterCallback && this.restartCounterCallback();
    Chart.Colorize();
    this.hideLoader();
  }

  protected clearState(): void {
    this.props.actionDispatcher.dispatch(
      dashboardActions.clearWorkTypeLiveDashboard()
    );
    this.setState({ filteredData: null, dataList: null });
  }

  protected showMore(): any {
    this.pageNumber += 1;
    this.retrieveData(this.state.selectedChannel);
  }

  private selectChannel(channel: IChannelCode): any {
    if (channel.selected) return;
    this.pageNumber = 0;
    this.channels.forEach((_channel: IChannelCode) => {
      _channel.selected = _channel.code === channel.code;
    })
    this.clearState();
    super.setState({ filteredData: null, dataList: null, selectedChannel: channel.code, searchText: '' });
    setTimeout(() => {
      this.retrieveData(channel.code);
    })
  }

  private navToServicesDashboard(): any {
    this.props.navigation('/inbound-live-dashboard');
  }
}

export default function InboundLiveDashboardTaskTemplates(props: any) {
  const navigation = useNavigate();

  return <InboundLiveDashboardTaskTemplatesView
    {...props}
    navigation={navigation}
    actionDispatcher={new ActionDispatcher(store)}
    params={useParams()}
  />;
}
