import { alertActions } from './alert.actions';
import { AuthService } from '../_services/auth.service';
import { ROLES, userConstants } from '../_constants';
import { appActions } from './app.actions';

class UserActions {
  private authService: AuthService = new AuthService();
  
  public login(email: string, password: string): any {
    return (dispatch: any) => {
      dispatch(this.loginRequest());

      this.authService
        .login(email, password)
        .then((response: IUser) => {
          const user = this.identifyUser(response);
          dispatch(this.loginSuccess(user));
          sessionStorage.setItem('zai-user', JSON.stringify(user));
        })
        .catch((error: Error) => {
          console.warn('Login failed', error);
          dispatch(this.loginFailure({ email, error }));
          dispatch(alertActions.error(error.toString()));
          dispatch(
            appActions.hideLoader('login')
          );
        });
    };
  }
  
  public loginWithAccessCode(accessCode: string): any {
    return (dispatch: any) => {
      dispatch(this.loginRequest());


      this.authService
        .loginWithAccessCode(accessCode)
        .then((response: IUser) => {
          const user = this.identifyUser(response);
          dispatch(this.loginSuccess(user));
          sessionStorage.setItem('zai-user', JSON.stringify(user));
        })
        .catch((error: Error) => {
          console.warn('Login failed', error);
          dispatch(this.loginFailure({ error }));
          dispatch(alertActions.error(error.toString()));
        })
    };
  }

  private loginRequest() { return { type: userConstants.LOGIN_REQUEST } }
  private loginSuccess(data: any) { return { type: userConstants.LOGIN_SUCCESS, data } }
  private loginFailure(error: any) { return { type: userConstants.LOGIN_FAILURE, error } }

  public logout(): any {
    return (dispatch: any) => {
      dispatch({ type: userConstants.LOGOUT });
    }
  }

  private identifyUser(data: any): any {
    
    const roleStrategy = {
      [ROLES.ADMINISTRATOR]: () => { data.hasAdministratorRole = true; },
      [ROLES.AGENT]: () => { data.hasAgentRole = true; },
      [ROLES.TEAM_LEADER]: () => { data.hasTeamLeaderRole = true; },
      [ROLES.OFFICE_EMPLOYEE]: () => { data.hasOfficeEmployeeRole = true; },
      [ROLES.QA]: () => { data.hasQARole = true; },
      [ROLES.QA_MANAGER]: () => { data.hasQAManagerRole = true; },
      [ROLES.CAMPAIGN_MANAGER]: () => { data.hasCampaignManagerRole = true; }
    };
    
    for (let userAccessRole of data.userAccessRoles) {
      if (userAccessRole.accountType === 'ORGANISATION' && roleStrategy[userAccessRole.role]) {
        roleStrategy[userAccessRole.role]();
        data.memberId = userAccessRole.memberId;
      }
    }
    return data;
  }
}

export const userActions = new UserActions();
