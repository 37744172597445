import React from 'react';

import { appActions, userActions } from '../_actions';
import { ApplicationState, store } from '../_helpers';
import { DashboardService } from '../_services/dashboard.service';

export class ListViewAbstract extends React.Component {

  public props: any;
  public state: { [key: string]: any } = {
    organisation: null,
    searchEnabled: false,
    searchText: '',
    selectedChannel: ''
  }
  protected loaderName = '';
  protected keyName: string = '';

  protected applicationState: ApplicationState = new ApplicationState(store);
  protected dashboardService: DashboardService = new DashboardService(); //todo: remove me
  protected pageNumber: number = 0;
  protected pageSize: number = 50;
  protected storeUnsubscribe: any;
  protected requestTimeout: number | null = null;
  protected timeout: number = 30 * 1000;
  protected mounted: boolean = false;
  protected restartCounterCallback: Function = () => {};
  protected loaders: string[] = [];

  public componentDidMount(): void {
    this.mounted = true;
    this.subscribeToStoreChanges();
    this.updateState();
  }

  public componentWillUnmount(): void {
    this.storeUnsubscribe();
    this.requestTimeout && window.clearInterval(this.requestTimeout);
    this.hideLoader();
    this.clearState();
  }

  private subscribeToStoreChanges(): void {
    this.storeUnsubscribe = this.applicationState.subscribe(() => {
      if (!this.props) return;
      this.updateState();
    });
  }

  protected updateState(): void {
    console.debug('updateState | not yet implemented ');
  }

  protected retrieveData(data?: any): void {
    console.debug('retrieveData | not implementted ');
  }

  protected clearState(): void {
    console.debug('clearState | not implementted ');
  }

  public convertTimeStampToDate(longestWaiting: any): any {
    if (longestWaiting === 0) return '00:00:00';
    const utcTimestamp = Date.parse(new Date(longestWaiting).toUTCString());
    return utcTimestamp;
  }

  public convertMilliSecondsToTime(ms: any): any {
    if (ms !== 0 && !ms) {
      return;
    }

    if (ms && ms.toString().indexOf(':') > -1) // Check if time already formatted
      return ms;

    let x, hours, minutes, seconds;
    x = Math.floor(ms / 1000);
    seconds = Math.floor(x % 60) < 10 ? '0' + Math.floor(x % 60) : Math.floor(x % 60);
    x /= 60;
    minutes = Math.floor(x % 60) < 10 ? '0' + Math.floor(x % 60) : Math.floor(x % 60);
    x /= 60;
    hours = Math.floor(x % 24) < 10 ? '0' + Math.floor(x % 24) : Math.floor(x % 24);
    x /= 24;

    return `${hours}:${minutes}:${seconds}`;
  }

  public showLoader(): void {
    if (this.loaders.includes(this.loaderName)) return;
  
    this.props.actionDispatcher.dispatch(
      appActions.showLoader(this.loaderName)
    );
  }

  public hideLoader(): void {
    if (!this.loaders.includes(this.loaderName)) return;
  
    this.props.actionDispatcher.dispatch(
      appActions.hideLoader(this.loaderName)
    );
  }

  public restartCounter(cb: Function): any {
    this.clearState();
    this.pageNumber = 0;
    this.restartCounterCallback = cb;
    setTimeout(() => {
      this.retrieveData(this.state.selectedChannel);
    });
  }

  protected showMore(): any {
    this.pageNumber += 1;
    this.retrieveData();
    console.debug('showMore | not overwritten');
  }

  public logout(): any {
    this.props.actionDispatcher.dispatch(
      userActions.logout()
    );
    this.props.navigation('/login');
  }

  public enableSearch(): any {
    this.setState({ searchEnabled: true });
  }

  public disableSearch(): any {
    this.setState({ searchEnabled: false, searchText: '' });
  }

  public timeChanged(time: number): any {
    if (!this.mounted) {
      return setTimeout(() => {
        this.timeChanged(time);
      }, 500);
    }
    if (time) {
      this.clearState();
      this.pageNumber = 0;
      this.timeout = time * 1000;
      this.retrieveData(this.state.selectedChannel);
    }
  }

  public searchTextChanged(event: any): any {
    this.setState({ searchText: event.target.value });
    const filteredData = this.state.dataList.map((ttd: any) => {
      ttd.visible = event.target.value === '' || ttd[this.keyName].toLowerCase().startsWith(event.target.value.toLowerCase());
      return ttd;
    });
    super.setState({ filteredData });
    this.forceUpdate();
  }
}
