import { organisationConstants } from './../_constants/organisation.constants';
import { OrganisationService } from '../_services/organisation.service';
import { alertActions } from './alert.actions';

class OrganisationActions {
  private organisationService: OrganisationService = new OrganisationService();
  
  public retrieveOrganisation(): any {
    return (dispatch: any) => {
      dispatch(organisationInfoRequest());

      this.organisationService
        .retrieveOrganisation()
        .then((response: IOrganisation) => {
          dispatch(organisationInfoSuccess(response));
          sessionStorage.setItem('zai-organisation', JSON.stringify(response));
        })
        .catch((error: Error) => {
          console.warn('failed to get organisation data', error);
          dispatch(organisationInfoFailure({ error }));
          dispatch(alertActions.error(error.toString()));
        });
    };

    function organisationInfoRequest() { return { type: organisationConstants.ORGANISATION_INFO_REQUEST } }
    function organisationInfoSuccess(data: IOrganisation) { return { type: organisationConstants.ORGANISATION_INFO_SUCCESS, data } }
    function organisationInfoFailure(error: any) { return { type: organisationConstants.ORGANISATION_INFO_FAILURE, error } }
  }
}

export const organisationActions = new OrganisationActions();
