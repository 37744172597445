import { ReactElement } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from "moment";

import { ActionDispatcher, store } from '../_helpers';
import ZPieChart from '../_components/PieChart';
import { ArrayTools } from '../_helpers/tools';
import BurgerTimer from '../_components/BurgerTimer';
import { Legend } from '../_components/Legend';
import { COLORS, LOADER } from '../_constants';
import { ListViewAbstract } from '../_components/_ListAbstract';
import { dashboardActions } from '../_actions/dashboard.actions';

class ContactCentreMonitoringTeamView extends ListViewAbstract {

  private legend = [
    { name: 'Interactions', categories: [
      { name: 'Missed', color: COLORS.RED }, { name: 'Connected', color: COLORS.GREEN }
    ] },
    { name: 'CC Connected', categories: [
      { name: 'No Disposition', color: COLORS.RED }, { name: 'Disposition', color: COLORS.GREEN }
    ] },
    { name: 'Agents', categories: [
      { name: 'Not Resp.', color: COLORS.RED }, { name: 'Conversing', color: COLORS.GREEN }, { name: 'Waiting', color: COLORS.ORANGE }, { name: 'Off Duty', color: COLORS.GREY }
    ] }
  ];
  public props: any;
  public state: { [key: string]: any } = {
    organisation: null,
    searchEnabled: false,
    searchText: ''
  }
  protected loaderName = LOADER.CONTACT_CENTRE_MONITORING_TEAM;
  protected loaders: string[] = [];
  protected keyName: string = 'fullName';

  public render(): ReactElement<any, any> {

    return (
      <section className="flex flex--direction-cols">
        <header className="flex flex--justify-between flex--align-center">
          {/* navigate back */}
          <button
            className="flex flex--align-center flex--justify-center background--blue-70 border-right--blue-45 width--60 height--full text-center border--none text--color-white font--size-15"
            onClick={() => this.navToContactCentreMonitoringDashboard()}
          >&#60;</button>
          <section className="flex--grow-1 text--color-white pad--10 overflow--hidden-x text--ellipse">&nbsp;Team: {this.state.teamName}</section>
          <section className="text--color-blue pad--10">
            <section onClick={() => this.logout()} className="width--20 height--20 pad--10">
              <section className="icon icon--power"></section>
            </section>
          </section>
        </header>
        {
          this.state.searchEnabled ?
          <section className="is-relative">
            <section className="flex flex--justify-between">
              <section onClick={() => this.disableSearch()} className="pad--left-10 pad--top-5 pad--bottom-5 pad--right-10 background--blue-70 border-right--blue-45">
                <section className="icon icon--search width--20__force"></section>
              </section>
              <input
                name="searchText"
                value={this.state.searchText}
                spellCheck="false"
                autoComplete="off"
                className="flex--grow-1 background--blue-dark text--color-white pad--left-20 pad--right-20 pad--top-7 pad--bottom-7 border--none border-bottom--blue-45__force font--size-9"
                type="text"
                placeholder="Search members"
                onChange={(event) => this.searchTextChanged(event)}
              />
            </section>
          </section> :
          <section className="flex flex--justify-between">
            <section className='background--blue-70 flex--basis-1-1 height--30__force'></section>
            <section onClick={() => this.enableSearch()} className="width--40 width--min-20 pad--left-10 pad--top-5 pad--bottom-5 pad--right-10 background--blue-70 border-left--blue-45">
              <section className="icon icon--search"></section>
            </section>
            <BurgerTimer
              view="contactCentreMonitoringAgentsDashboard"
              timeChanged={(time: number) => this.timeChanged(time)}
              restartCounter={((cb: Function) => this.restartCounter(cb))}
            ></BurgerTimer>
          </section>
        }
        <Legend data={this.legend}></Legend>
        <summary
          id="js-summary-members"
          className="flex flex--direction-cols text--color-white overflow--auto-y u-scroll-bar u-scroll-bar--blue contentHeight--200"
        >
          {
            this.state.filteredData && this.state.filteredData.length > 0 ?
              this.state.filteredData.map((item: any, index: number) => 
                <summary
                  key={`item-${index}`}
                  className={`flex flex--align-center ${item.visible === false ? 'is-hidden' : ''} ${this.state.filteredData.length - 1 === index ? 'mar--bottom-100' : '' }`}
                >
                  <section className="font--size-6 name-col pad--left-10">
                    <section className={`text--wrap-none text--color-blue font--size-7 ${(item.fullName || '').length > 15 ? 'marquee' : ''}`}>
                      <section style={{
                        animation: `marquee ${(
                          item.fullName.length > 45 ? item.fullName.length / 6 : (
                            item.fullName.length > 40 ? item.fullName.length / 5.5 : (
                              item.fullName.length > 35 ? item.fullName.length / 5 : (
                                item.fullName.length > 30 ? item.fullName.length / 4.5 : (
                                  item.fullName.length > 25 ? item.fullName.length / 4 : (
                                    item.fullName.length > 20 ? item.fullName.length / 3.5 : (
                                      item.fullName.length > 15 ? item.fullName.length / 3 : 0
                                    )
                                  )
                                )
                              )
                            )
                          )
                        )}s linear infinite`
                      }}>{item.fullName}</section>
                    </section>

                    <section>
                      AHT:&nbsp;{super.convertMilliSecondsToTime(item.times.averageHandle)}
                    </section>
                    <section className="flex flex--align-center">
                      Transfers:&nbsp;
                      {
                        item.transfers > 0 ?
                        <section style={{ background: '#800020', padding: '0 10px' }}>{item.transfers}</section> :
                        <>{item.transfers}</>
                      }
                      
                    </section>
                    <section className="marquee">
                      <section
                        className="flex flex--align-center"
                        style={{
                          animation: `marquee 6.5s linear infinite`
                        }}
                      >
                        <section className="text--wrap-none">Conversing Time:</section>&nbsp;
                        {
                          item.times.conversing > 0 ? 
                          <section style={{ background: '#124429', padding: '0 10px' }}>{super.convertMilliSecondsToTime(item.times.conversing)}</section> :
                          <>00:00:00</>
                        }
                      </section>
                    </section>
                    <section className="marquee">
                      <section
                        className="flex flex--align-center"
                        style={{
                          animation: `marquee 6.5s linear infinite`
                        }}
                      >
                        Off Duty Time:&nbsp;{super.convertMilliSecondsToTime(item.times.notReady)}
                      </section>
                    </section>
                  </section>

                  <section className="flex--basis-1-1">
                    <section className="flex flex--direction-cols flex--align-center">
                      <ZPieChart
                        size={ZPieChart.SIZES.SMALL}
                        item={item}
                        data={[{
                          name: 'Not Handled',
                          value: -Math.round(-item.served.notHandled * 10) / 10,
                          percentage: (item.served.notHandled > 0 ? -Math.round(-((item.served.notHandled / item.served.total) * 100)): 0) + '%'
                        }, {
                          name: 'Handled',
                          value: -Math.round(-item.served.handled * 10) / 10,
                          percentage: (item.served.handled > 0 ? -Math.round(-((item.served.handled / item.served.total) * 100)): 0) + '%'
                        }, !(item.served.notHandled > 0 || item.served.handled > 0) ? {
                          name: '',
                          value: 100,
                          percentage: ''
                        } : null]}
                        innerLabel={item.served.total}
                      ></ZPieChart>
                    </section>
                  </section>

                  <section className="flex--basis-1-1">
                    <section className="flex flex--direction-cols flex--align-center">
                      <ZPieChart
                        size={ZPieChart.SIZES.SMALL}
                        item={item}
                        data={[{
                          name: 'No dispositions selected',
                          value: -Math.round(-item.dispositions.notSelected * 10) / 10,
                          percentage: (item.dispositions.notSelected > 0 ? -Math.round(-((item.dispositions.notSelected / item.dispositions.total) * 100)): 0) + '%'
                        }, {
                          name: 'Dispositions selected',
                          value: -Math.round(-item.dispositions.selected * 10) / 10,
                          percentage: (item.dispositions.selected > 0 ? -Math.round(-((item.dispositions.selected / item.dispositions.total) * 100)): 0) + '%'
                        }, (item.dispositions.notSelected > 0 || item.dispositions.selected > 0) ? 
                        null : 
                        {
                          name: '',
                          value: 100,
                          percentage: ''
                        }]}
                        innerLabel={item.dispositions.total}
                      ></ZPieChart>
                    </section>
                  </section>

                  <section className="flex--basis-1-1">
                    <section className="flex flex--direction-cols flex--align-center">
                      <ZPieChart
                        size={ZPieChart.SIZES.SMALL}
                        item={item}
                        data={[{
                          name: 'Not Resp.',
                          value: item.currentActivity.name === 'NOT_RESPONDING' ? 100 : 0,
                        }, {
                          name: 'Conversing',
                          value: item.currentActivity.name === 'CONVERSING' ? 100 : 0,
                        }, {
                          name: 'Waiting',
                          value: item.currentActivity.name === 'WAITING' ? 100 : 0,
                        }, {
                          name: 'Off Duty',
                          value: item.currentActivity.name === 'NOT_READY' ? 100 : 0,
                        }, !item.currentActivity.name ? {
                          name: '',
                          value: 100,
                          percentage: ''
                        } : null]}
                        innerLabel={this.getTime(item.currentActivity.startTimestamp)}
                      ></ZPieChart>
                    </section>
                  </section>
                </summary>
            ) :
            <section
              className="font--size-8 text--color-blue text-center pad--10 mar--top-20">
              No data to display.
            </section>
          }
          {
            this.state.showMore ?
            <section
              onClick={() => this.showMore()}
              className="font--size-8 background--blue-70 text--color-blue text-center pad--10 mar--top-20">
              SHOW MORE
            </section> :
            <></>
          }
        </summary>
      </section>
    );
  }

  protected updateState(): void {
    let { app, authentication, dashboard } = this.applicationState.getState();

    if (authentication && authentication.loggedIn) {
      this.loaders = app.actions || [];
      if (!dashboard.contactCentreMonitoringAgents || dashboard.contactCentreMonitoringAgents.loading) return;
    
      if (dashboard.contactCentreMonitoringAgents && dashboard.contactCentreMonitoringAgents.members) {
        this.dataRetrieved(dashboard.contactCentreMonitoringAgents);

        const state = this.props.location.state;
        this.setState({ teamName: state.teamName })
      }
    }
  }

  private searchTimeout: any;

  public searchTextChanged(event: any): void {
    this.pageNumber = 0;
    this.setState({ searchText: event.target.value });

    this.searchTimeout && window.clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {
      this.retrieveData(event.target.value);
    }, 500);
  }

  protected retrieveData(searchText?: any): any {

    setInterval(() => {
      let timer = this.state.timer || 0;
      timer ++;
      this.setState({ timer  });
    }, 1000)
    try {
      if (this.pageNumber === 0) {
        this.clearState();
      }
      this.showLoader();
      
      const teamId = this.props.params.id;
      this.props.actionDispatcher.dispatch(
        dashboardActions
            .retrieveContactCenterMonitoringAgentsDashboard(teamId, this.pageNumber, this.pageSize, searchText)
      );
    } catch(e) {
      console.error(' > failed to retrieve dashboard', e);
      this.hideLoader();
    }
  }

  private dataRetrieved(data: any): void {
    let members = ArrayTools.sort(data.members, 'name');

    super.setState({
      teamName: data.teamName,
      dataList: members,
      filteredData: members,
      showMore: data.showMore
    });
    this.restartCounterCallback && this.restartCounterCallback();
    this.hideLoader();
  }

  protected clearState(): void {
    this.props.actionDispatcher.dispatch(
      dashboardActions.clearContactCentreMonitoringAgentsLiveDashboard()
    );
    this.setState({ filteredData: null, dataList: null });
  }

  protected showMore(): any {
    this.pageNumber += 1;
    this.retrieveData(this.state.searchText);
  }

  private navToContactCentreMonitoringDashboard(): any {
    this.props.navigation('/contact-centre-monitoring');
  }

  private getTime(value: any): string {
    if (value) {
      const diff = moment.duration(moment.utc().diff(moment(value).utc()));
      const hours = diff.hours() > 9 ? diff.hours() : '0' + diff.hours();
      const minutes = diff.minutes() > 9 ? diff.minutes() : '0' + diff.minutes();
      const seconds = diff.seconds() > 9 ? diff.seconds() : '0' + diff.seconds();
      const readable = hours + ':' + minutes + ':' + seconds;
      return readable;
    }
    return '';
  }
}

export default function ContactCentreMonitoringTeam(props: any) {
  const navigation = useNavigate();
  const location = useLocation();

  return <ContactCentreMonitoringTeamView
    {...props}
    navigation={navigation}
    location={location}
    actionDispatcher={new ActionDispatcher(store)}
    params={useParams()}
  />;
}
