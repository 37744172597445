import { alertConstants } from '../_constants';

export const alertActions = {
    success,
    error,
    clear
};

function success(message: IReduxMessage): IReduxResponse {
    return { type: alertConstants.SUCCESS, message };
}

function error(message: IReduxMessage): IReduxResponse {
    return { type: alertConstants.ERROR, message };
}

function clear(): IReduxResponse {
    return { type: alertConstants.CLEAR };
}